body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #2731c6; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #404040;
  font-weight: 200;
  font-family: helvetica Neue;
}
h2 {
  color: #404040;
  font-weight: 200;
  font-family: helvetica Neue;
}
h3 {
  color: #404040;
  font-weight: 200;
  font-family: helvetica Neue;
}
h4 {
  color: #404040;
  font-weight: 200;
  font-family: helvetica Neue;
}
p {
  color: #404040;
  font-weight: 200;
  font-family: helvetica Neue;
}

@font-face {
  font-family: 'DisneyFont';
  src: url('waltograph42.otf') format('otf');
}

