
.header {
  font-family: DisneyFont;
  color: red;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// h1.letterpress {
//   font-family: helvetica neue;
//   /* color: rgb(36, 42, 158); */
//   font-size: 4em;
//   text-align: left;
//   text-shadow: 1px 1px rgba(133, 94, 240, 0.8);
//   margin: 0;
// }

.container {
  padding: 20px;
}

.headerContainer {
  h1 {
    font-size: 4em;
    text-align: center;
    color: #ba8b38;
    margin: 0;
    font-family: InspireTWDC, AvenirNext, Matterhorn, InspireTWDC, Helvetica, sans-serif;
    font-weight: 200;
  }
}

hr.goldRule {
  background-image: url("https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/500/40/75/dam/disney-world/50th-anniversary/graphics/decorative-rule/dak_star_rule_1x.jpg?1674660924659");
  width: 500px;
  height: 40px;
  border: 0;
}

.rowInfo {
  width: 600px;
  margin: 0 auto;
}

.button {
  width: 200px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
}

.buttonWrapper {
  margin: 0 auto;
  width: 200px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
